import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import qs from 'qs'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}
const actions = {
  // user login
  login({ commit }, userInfo) {
      const { enterpriseName, password,username} = userInfo
      return new Promise((resolve, reject) => {
          let loginInfo={username: username, password: password,enterpriseName:enterpriseName};
          login(loginInfo).then(response => {
              const { data,code,msg,message } = response;
              if(code=='1'){
                commit('SET_TOKEN', data)
                setToken(data)
                resolve()
              }else {
                  resolve(message)
              }

          }).catch(error => {
              reject(error)
          })
      })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token).then(response => {
      //     const { data } = response
      //     // const data = response.data
      //     localStorage.setItem("navList", JSON.stringify(data));
      //     if (!data) {
      //     reject('Verification failed, please Login again.')
      //   }
      //     //处理数组数据
      //     let oneList=[];
      //     data.forEach( item =>{
      //         if(item.parentId=='0'&&item.isMenu=='0'){
      //             item.children=[];
      //             oneList.push(item)
      //         }
      //     })
      //     data.forEach(item =>{
      //         if (item.parentId!='0'&&item.isMenu=='0'){
      //             oneList.forEach( it=>{
      //                 if(item.parentId== it.id){
      //                     it.children.push(item)
      //                 }
      //             })
      //         }
      //     })

      //     const { roles, name, avatar, introduction } = data

      // }).catch(error => {
      //   reject(error)
      // })


        // localStorage.setItem("navList", []);
        commit('SET_ROLES', ["admin"])
        commit('SET_NAME', "Super Admin")
        commit('SET_AVATAR', "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif")
        commit('SET_INTRODUCTION', "I am a super administrator")
        resolve({
            avatar: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
            introduction: "I am a super administrator",
            name: "Super Admin",
            roles:["admin"]
        })

    })
  },


  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      //   commit('SET_TOKEN', '')
      //   commit('SET_ROLES', [])
      //   removeToken()
      //   resetRouter()

      //   // reset visited views and cached views
      //   // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      //   dispatch('tagsView/delAllViews', null, { root: true })

      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
