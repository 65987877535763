// const baseURL = 'https://192.168.5.100:8443/';
// const baseURL = 'https://47.99.204.31:8443/';
// const baseURL = 'https://www.jobs-interviews.com';
// const baseURL = 'https://192.168.5.10:8443/';
// const baseURL = 'https://192.168.5.12:8443/';
const baseURL = 'https://clinpharmaclinicalresearch.com:8449/';
// const baseURL = 'https://192.168.2.11:8449/';
// const baseURL = 'http://192.168.5.22:8082/';



// const baseURL = 'https://192.168.5.100:8443/';
// const baseURL = 'https://www.jobs-interviews.cn/';
//const baseURL = "https://192.168.5.49:8443/";
export default baseURL;
