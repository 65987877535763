import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import baseURL from '@/api/baseURL.js'
import baseURL2 from '@/api/baseURL2.js'
import qs from 'qs'
import Cookies from 'js-cookie'

// import jsonBig from 'json-bigint'
// create an axios instance
const language=Cookies.get('ClinLan')

const service = axios.create({
  baseURL: language=='zh-Cn'?baseURL:baseURL2, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = getToken();
    if (token) {
      config.headers['Authorization'] = getToken()
    }
    if (config.data) {
      if (config.url == '/video-interview/insert') {
        config.data = config.data
      } else if (config.headers['Content-Type'] == 'application/json') {
        config.data = config.data
      } else {
        config.data = qs.stringify(config.data)
      }

      // config.data['token'] = getToken();
      // config.data['choicedLanguage'] =language;
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    // return res
    // if the custom code is not 20000, it is judged as an error.
    if (res.resultCode !== '0') {
      // Message({
      //     message: res.message || 'Error',
      //     client-type: 'error',
      //     duration: 5 * 1000
      // })
      if (res.resultCode) {
        if (res.resultCode == '3') {
          //登录超时返回登录页
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        } else {
          // return Promise.reject(new Error(res.message || 'Error'))
          return res
        }
      } else {
        //验证码无状态（resultCode）直接返回
        return res
      }

    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    let codes = error.message.substring(error.message.length - 3, error.message.length);
    if (codes == '403') {
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
