<template>
  <div id="app" v-title :data-title="webData.name" :data-keywords="webData.keyword" v-if="webData.id">
    <router-view/>
  </div>
</template>

<script>
import {post, del, get, putUrl} from "@/api/http";
import Cookies from 'js-cookie'
import request from "@/utils/request.js";

export default {
  name: 'App',
  data() {
    return {
      webData:{},
      iconUrl:''
    }
  },
  methods:{
     async getData(){
      let res = await post("/webWebsiteInformation/getDetails")
      this.webData=res.data
      Cookies.set('webinfo',JSON.stringify(this.webData))
      // 修改icon
      if(res.data.urlBarIcon){
        // let res2 = await get("/file/oss-url/"+res.data.urlBarIcon)
        var res2 = await request({
          url: "/file/download",
          method: "POST",
          params: {
            id: res.data.urlBarIcon,
          },
          isDownLoadFile: true,
          responseType: "blob",
        });
        const blob = new Blob([res2], {
          type: "application/vnd.ms-excel",
        });
        const linkNode = document.createElement("a");
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = linkNode.href;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      // 修改keyword和description
        var meta = document.querySelector("meta[name*='keywords']") || document.createElement('meta');
        var meta2 = document.querySelector("meta[name*='description']") || document.createElement('meta');
        meta.content = res.data.keyword
        meta2.content = res.data.description
        document.getElementsByTagName('head')[0].appendChild(meta);
        document.getElementsByTagName('head')[0].appendChild(meta2);
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style>
*{
  padding:0;
  margin:0;
}
body,html{
  width:100%;
}
ul,ol,li{
  list-style: none;
}
#app {
  font-family: "Microsoft YaHei",'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  font-size: 22px;
}
a{
  color:#0e63dc;
  /* text-decoration:none; */
}
img{
  display: block;
  width: 100%;
}
.pos-rl{
  position: relative;
}
.dis-flex{
  display: flex;
}
/* 字体细 */
.ft-slim{
  font-weight: 300;
}
.txt-rt{
  text-align: right;
}
.dis-inbl{
  display: inline-block;
}
.dis-right{
  display: flex;
  justify-content: end;
}
.sp-between{
  justify-content: space-between;
}
.inner{
  width: 1620px;
  margin:0 auto;
}
.p16-txt p{
  margin-bottom: 18px;
}
.p16-txt h3{
  margin-bottom: 20px;
}
.p22-txt p{
  margin-bottom: 20px;
}
  /*css主要部分的样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/

::-webkit-scrollbar {
width: 10px; /*对垂直流动条有效*/
height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
border-radius: 3px;
}


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
border-radius: 7px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
background-color: #E8E8E8;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
display: none;
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
display: none;
}
</style>
