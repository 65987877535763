export default {
  // menu: {
  //   home: "首页",
  //   learn: "学习",
  //   questionBank: "题库",
  //   discuss: "讨论",
  //   jobWanted: "求职",
  // },
  common: {
    bottomIntro:'多个领域，满足客户多元化需求，全方位助力人才和客户的高质量发展',
    read:'阅读',
    loadmore:'加载更多',
    delivery:'本人投递',
    referdelivery:'推荐人投递',
    search:'搜索',
    navigation:'导航',
    service:'服务',
    pleaseEnter:'请输入内容',
    submitApply:'提交申请',
    ifReference:'如果你有推荐人',
    reference:'推荐人',
    oneReferenced:'被推荐人',
    applyPosition:'申请职位',
    backList:'返回列表',
    clickUpload:'点击上传',
    name:'姓名',
    email:'邮箱',
    phoneNumber:'手机号',
    resumeUpload:'简历点击上传',
    hisName:'他/她的姓名',
    hisEmail:'他/她的邮箱',
    hisPhoneNum:'他/她的手机号',
    attaUpload:'附件上传',
    submitFeedback:'提交反馈',
    content:'内容',
    contactUs:'请与我们联系',
    submitSuccess:'提交成功',
    pleaseComplete:'请输入完整信息！',
    pleaseEnter:'请输入',
    jobInfo:'我们正在寻找富有才华、积极进取的员工，如果你有研发潜力并对科技创新充满激情和热爱，欢迎申请下面空缺职位；如果你不知道申请哪个职位或者找不到合适你的职位，请点击下方按钮直接申请',
    generalApply:'综合申请',
    previous:'上一篇',
    next:'下一篇',
    visits:'访问量',
    releaseTime:'发布时间',
    filterfields:'条件搜索',
    Workplace:'工作地点',
    all:'全部',
  },
};
