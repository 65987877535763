import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Index from '../views/Index'

Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'HelloWorld',
    //   component: HelloWorld
    // },
    // {
    //   path: '/',
    //   name: 'Index',
    //   component: Index
    // },
    {
      path: '/',
      component: Index,
      name: 'Index',
      children: [
        {
          path: '/',
          name: 'Home',
          component: () =>import("../views/Home.vue"),
          meta: { title: '首页', icon: 'home'}
        },
        {
          path: '/cases',
          name: 'Cases',
          component: () =>import("../views/Cases.vue"),
          meta: { title: '合作商', icon: 'news'}
        },
        {
          path: '/news',
          name: 'News',
          component: () =>import("../views/News.vue"),
          meta: { title: '新闻', icon: 'news'}
        },
        {
          path: '/news-detail',
          name: 'News-detail',
          component: () =>import("../views/News-detail.vue"),
          meta: { title: '新闻', icon: 'news'}
        },
        {
          path: '/recruit',
          name: 'Recruit',
          component: () =>import("../views/Recruit/Recruit.vue"),
          meta: { title: '招聘', icon: 'news'},
        },
        {
          path: '/recruit-detail',
          name: 'Recruit-detail',
          component: () =>import("../views/Recruit/Recruit-detail.vue"),
          meta: { title: '招聘', icon: 'news'}
        },
        {
          path: '/feedback',
          name: 'Feedback',
          component: () =>import("../views/Feedback.vue"),
          meta: { title: '客户反馈', icon: 'news'}
        },
        {
          path: '/about-us',
          name: 'AboutUs',
          component: () =>import("../views/AboutUs.vue"),
          meta: { title: '关于我们', icon: 'news'}
        },
        {
          path: '/service',
          name: 'Service',
          component: () =>import("../views/Service.vue"),
          meta: { title: '业务服务', icon: 'news'}
        },
        {
          path: '/product',
          name: 'Product',
          component: () =>import("../views/Product.vue"),
          meta: { title: '产品', icon: 'news'}
        },
      ]
    },
  ]
})
