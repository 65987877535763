export default {
  menu: {
    home: "首页",
    learn: "学习",
    questionBank: "题库",
    discuss: "讨论",
    jobWanted: "求职",
  },
  common: {
    bottomIntro:'Multiple fields to meet the diversified needs of customers, all-round support for the high-quality development of talents and customers',
    read:'Read',
    loadmore:'Load More',
    delivery:'Self delivery',
    referdelivery:'Referral delivery',
    search:'Search',
    navigation:'Navigation',
    service:'Service',
    pleaseEnter:'Please enter the content',
    submitApply:'Submit application',
    ifReference:'If you have a reference',
    oneReferenced:'Recommended person',
    reference:'Recommender',
    applyPosition:'Apply the position',
    backList:'Return to list',
    clickUpload:'Click upload',
    name:'Enter your name',
    email:'Enter your email',
    phoneNumber:'Enter your phone number',
    resumeUpload:'Browse your resume',
    hisName:'Enter his/her name',
    hisEmail:'Enter his/her email',
    hisPhoneNum:'Enter his/her phone number',
    attaUpload:'Attachment upload',
    submitFeedback:'Submit feedback',
    content:'Content',
    contactUs:'Get in touch with us',
    submitSuccess:'Submitted successfully',
    pleaseComplete:'Please enter complete information!',
    pleaseEnter:'Please enter ',
    jobInfo:"We are looking for talented and motivated employees. If you have R&D potential and passion and love for technological innovation, please apply for the following vacancies. If you don't know which position to apply for or can't find the right one for you, please click the button below to apply directly.",
    generalApply:'General Application',
    previous:'Previous',
    next:'Next',
    visits:'Pageviews',
    releaseTime:'Release time',
    filterfields:'Filter fields',
    Workplace:'Workplace',
    all:'ALL',
  },
};
