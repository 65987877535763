
import request from '@/utils/request.js'
import axios from 'axios'
import baseURL from '@/api/baseURL.js'
import { getToken } from '@/utils/auth'

// export const list = (data) => {
// return request({
//     url: baseUrl + '/fly63/api/list',
//     method: 'post',
//     params: data,
//     transformResponse: [ data => {
//         data = JSONbig.parse(data);
//         data.list.filter((item)=>{
//             item.id = item.id.toString();
//             return item;
//         })
//         return data;
//     }],
// })}

function post (url, data) {
  return request({
    url,
    method: "POST",
    data,
  });

}

function get (url, data) {
  return request({
    url,
    method: "GET",
    params: data
  });
}

function del (url, data) {
  return request({
    url,
    method: "DELETE",
    params: data
  });
}

function putUrl (url, data) {
  return request({
    url,
    method: "PUT",
    params: data
  });
}

function uploadImg (url, data) {
  return axios({
    url: baseURL + url,
    method: 'post',
    headers: { "Content-Type": "multipart/form-data", "Authorization": getToken() },
    data: data
  })
}
//传json格式文件
function postJSON (url, data) {
  return request({
    url,
    method: "POST",
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  });

}

export {
  post, get, del, putUrl, uploadImg, postJSON
}
