<template>
<el-container>
  <el-header>
    <div class="navbar">
          <div class="logo-box">
            <div class="logo" v-if="logo.id">
              <!-- <img :src="logo.url" v-if="logo.url!==''" alt=""> -->
              <img  :src="logo.logoUrl" :onerror="getImage(logo)"  v-if="logo.logoUrl" alt="" />
              <img src="../../static/logo.png" v-else alt="">
            </div>
          </div>
          <div class="nav-menu">
            <el-menu :default-active="activeIndex" router class="el-menu" mode="horizontal" background-color="#F2F7FD"
              text-color="#94A3BC" active-text-color="" @select="RouteSelect">
                 <div v-for="item in columnList.slice(0,7)" :key="item.id">
                  <div v-if="item.navigationDisplay==0||item.navigationDisplay==1">
                    <el-menu-item :index="item.link" :route="{ path: item.link, query: {id:item.id} }" 
                      v-if="item.link!=='#service'">
                      <span>{{item.name}}</span>
                    </el-menu-item>
                    <el-submenu index="" v-else>
                      <div slot="title">{{item.name}}</div>
                      <el-menu-item index="/service" v-for="(item,index) in businessData" :key="item.id" @click="gotoService(item,index)">{{item.name}}</el-menu-item>
                    </el-submenu>
                  </div>
                 </div>
            </el-menu>
          </div>
          <div class="language">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ language }}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh-Cn">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
    </div>
  </el-header>
  <router-view />
  <!-- footer -->
  <div class="footer ">
    <div class="inner dis-flex sp-between">
      <div class="left">
        <div class="logo-bottom" v-if="logo.id">
            <img  :src="logo.logoUrl" :onerror="getImage(logo)" v-if="logo.logoUrl" alt="" />
            <img src="../../static/logo.png" v-else alt="">
        </div>
        <div class="intrduce ft-slim">
          <p>{{$t('common.bottomIntro')}}</p>
        </div>
      </div>
      <div class="right dis-flex">
        <div class="menu">
          <h4>{{$t('common.navigation')}}</h4>
          <!-- <router-link :to="item.link" v-for="item in columnList" :key="item.id" @click="bottomClick(item)">{{item.name}}</router-link> -->
          <a v-for="item in columnList" :key="item.id" @click="bottomClick(item)"><span v-if="item.navigationDisplay==0||item.navigationDisplay==2">{{item.name}}</span></a>
        </div>
        <div class="menu">
          <h4>{{$t('common.service')}}</h4>
          <!-- <router-link :to="item.link" v-for="(item,index) in businessData" :key="item.id" @click="gotoService(item,index)">{{item.name}}</router-link> -->
          <a v-for="(item,index) in businessData" :key="item.id" @click="gotoService(item,index)">{{item.name}}</a>
        </div>
        <!-- <div class="menu">
          <h4>Further Information</h4>
          <router-link to="/">Terms &Conditions</router-link>
          <router-link to="/">Privacy Policy</router-link>
        </div> -->
      </div>
    </div>
  </div>
  <!-- copyright -->
  <div class="copyright">
    <span v-html="copyright"></span>
    <!-- <span>© Copyright 2004-2022 - CLINPHARMA CLINICAL RESEARCH LLC.</span> -->
  </div>
</el-container>
</template>

<script>
// import store from '@/store'
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";


export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      // activeIndex: this.$store.state.navbar,//当前激活菜单index
      activeIndex: '/',//当前激活菜单index
      language: '',
      searchtxt: '',
      routes:router.options.routes,
      route:router.options.routes[0].children,
      routeIndex: this.$route.path,
      infoDialog: false,
      updateDialog: false,

      columnList:[],//首页导航
      businessData:[],
      logo:{},
      copyright:''
    }
  },
  watch: {
    // logo:{
    //   handler(newName, oldName) {
    //       this.logo = newName
    //   },
    //   deep: true
    // },
    $route: {
			handler(newVal, oldVal) {
				if(newVal){
          this.activeIndex = newVal.path
          if(newVal.path=='/news-detail'){
            this.activeIndex = '/news'
          }
				}
			},
			deep: true
		}
  },
  // 引入store里的navbar
  computed:{
    ...mapGetters([
      'navbar',
    ]),
  },
  methods: {
    // 获取导航
    getColumn(){
      post("/publicController/homeColumn").then(res => {
          if (res.code == 1) {
              this.columnList=res.data
          } else {
              this.$message.error(res.data);
          }
      });
    },

    RouteSelect(key, keyPath) {
      let cur = Cookies.get('home-nav')
      if(key.slice(0,1)=='#'){
        if(cur=='/'){
          this.$bus.emit("anchor",key)
        }else{
          this.$router.push({ path: '/'});
          setTimeout(()=>{
            this.$bus.emit("anchor",key)
          },2500)
        }
      }else{
        if(cur !== key){
          this.activeIndex = key
          this.$store.dispatch('app/setNavbar',key)
        }
      }
    },
    getData(id){
      if(id){
        setTimeout(()=>{
          post("/publicController/publicData",{id:id}).then(res => {
              if (res.code == 1) {
                  this.businessData=res.data.business
              } else {
                  this.$message.error(res.data);
              }
          });
        },500)
      }
    },
    bottomClick(row){
      this.$store.dispatch('app/setNavbar',row.link)
      this.$router.push({ path: row.link, query: row });
    },
    gotoService(data,index){
      data.index = index
      this.$store.dispatch('app/setNavbar','/service')
      setTimeout(()=>{
        this.$router.push({ path: "/service", query: {id:data.id,parentId:data.parentId,index:data.index} })
      },200)
    },
    handleCommand(lang) {
      console.log(lang)
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success',
      })
      location.reload()
    },
    getService(){
      // this.getData('1554753152097501186')
    },
    // 获取图片地址
    //  async getImage(row){
    //   if(row.logo){
    //     let res = await get("/file/oss-url/"+row.logo)
    //     row.logoUrl = res.data
    //     row.onerror = null
    //   }
    // },
    getImage(row) {
      setTimeout(function () {
        if (row.logo && !row.logoUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.logo
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.logoUrl =  linkNode.href 
          })
        }
      }.bind(this), 500);
    },
  },
  mounted() {
    this.activeIndex = this.$route.path||Cookies.get('home-nav')
    if(this.$route.path=='/news-detail'){
      this.activeIndex = '/news'
    }
    this.getColumn()
    // 获取服务范围
    this.$nextTick(()=>{
      this.getData('1554753152097501186')
    })
    this.logo = JSON.parse(Cookies.get('webinfo'))
    this.copyright = JSON.parse(Cookies.get('webinfo')).copyright

    if (Cookies.get('ClinLan') == 'en') {
      this.language = 'English'
    } else {
      this.language = '中文'
    }
  },
}
</script>
<style lang="scss">
.el-container{
  min-width:1620px !important;
}
// 头部
.el-header{
  background-color: #F2F7FD;
  // line-height: 90px;
  height:90px !important
}
.navbar {
  height: 90px;
  width: 1620px;
  margin:0 auto;
  display: flex;
  align-items: center;
  .logo-box {
    text-align: left;
    width:40%;
    max-height: 90px;
    overflow: hidden;
  }
  .logo {
    width:287px;
  }
  .logo img{
    width:100%;
    display: block;
  }

  .nav-menu {
    width:60%;
    height: 90px;
    float: left;
    color:#94A3BC;
    line-height: 90px;
    margin-right: 30px;
    .el-menu.el-menu--horizontal{
      border:none;
    }
  }
  .nav-menu ul {
    height: 90px;
    display: flex;
  }

  .nav-menu ul .el-menu-item {
    height: 90px;
    width: 16.7%;
    overflow: hidden;
    line-height: 90px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 16px;
    .svg-icon{
      margin-right: 3px;
    }
  }
  .nav-menu ul li:hover {
    background-color: transparent !important;
    color: #80adec !important;
  }

  .el-menu--horizontal li.el-menu-item,.el-submenu {
    box-sizing: border-box;
    height: 50px;
    min-width:110px;
    max-width:125px;
    overflow: hidden;
    line-height: 45px;
    margin-top: 18px;
    text-align: center;
    border-radius: 16px;
    opacity: 1;
  }
  .el-menu--horizontal .el-menu-item.is-active {
    background-color: #0063E0 !important;
    color:#fff;
  }
  .el-menu--horizontal .el-menu-item.is-active:focus {
    color:#fff;
  }
  .el-submenu .el-submenu__title{
    line-height: 50px;
    height: 50px;
    min-width: 100px;
    font-size: 16px;
    color:#94A3BC;
    border-radius: 16px;
  }
  .el-submenu .el-submenu__title:hover{
    background-color: transparent !important;
    color:#80adec !important;
  }
  .el-submenu.is-active .el-submenu__title{
    background-color: #0063E0 !important;
    color:#fff;
  }
  .el-submenu.is-active{
    color:rgb(148, 163, 188) !important;
    background-color: #0063E0 !important;
  }
  .el-menu--horizontal .el-menu.el-menu--popup .el-menu-item.is-active{
    color:rgb(148, 163, 188) !important;
  }
  .el-submenu__icon-arrow{
    right:10px
  }

  .language{
    height: 90px;
    line-height: 90px;
    cursor: pointer;
    text-align: center;
    .el-dropdown{
      height: 100%;
      width:70px;
    }
  }
}
::v-deep .el-popover{
  color:#333;
}
 .menubar .el-menu-item [class^=el-icon-]{
    font-size: 12px !important;
    width: 6px;
  }

.main-container{
  margin:20px 10px 20px 20px;
  padding:20px; 
  background-color: #fff;
}

.footer{
  background: linear-gradient(to right, #EFF4FD , #ffffff);
  padding:100px 0;
  .right{
    .menu{
      width:270px;
    }
    h4{
      margin-bottom: 20px;
    }
    a{
      font-weight: 300;
      font-size: 20px;
      display: block;
      line-height: 40px;
      color: #333;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .left{
    .logo-bottom{
      width:287px;
      height: 38px;
      overflow: hidden;
      margin-bottom: 80px;
      max-height: 90px;
      img{
        width:100%;
        display: block;
      }
    }
    .intrduce{
      width:400px;
      line-height: 150%;
    }
  }
}
.copyright{
  height: 70px;
  line-height: 70px;
  background-color: #E2EEFC;
  color:#80adec;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
</style>
