import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import './styles/theme/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import i18n from './lang/index'
import locale from 'element-ui/lib/locale/lang/en'

//懒加载
import VueLazyload from 'vue-lazyload'

// bus
import VueBus from 'vue-bus';
// 自适应
// import './utils/flexible.js'
import '@/utils/rem';


import './icons' // icon

// import './permission'

import { pageSize, pageSizes } from "./views/pageParameter"

Vue.prototype.$pageSize = pageSize;
Vue.prototype.$pageSizes = pageSizes;

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(mavonEditor);
Vue.use(VueLazyload)
Vue.use(VueBus);

// 修改网站title
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
    document.keywords = el.dataset.keywords
  }
})

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
